import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                category: 'toggleCategory',
                subCategory: 'toggleSubCategory',
                // crossNav: 'toggleCrossNav',
                // crossInfowindow : 'toggleCrossInfowindow',
                closeInfowindow: 'closeInfowindow',
            },

            change: {
                changeSubCategory: 'toggleSubCategory',
            },
        }

        this.map = false;
        this.categories = this.subcategories = [];
    }

    init() {
        const el = this.$('map');
        setTimeout(() => {
            if (google !== undefined) {
                this.map = initMap($(el))
            }
        }, 2000);
        document.addEventListener("googlemaps_loaded", function() {
            setTimeout(() => {
                if (google !== undefined && this.map === false) {
                  this.map = initMap($(el));
                }
            }, 2000);
        });
    }

    toggleCategory(e) {
        let target = e.currentTarget;
        
        let categories = this.categories;
        let id = '';

        // if (e.type === 'change'){
        //     id = Number(target[target.options.selectedIndex].getAttribute('data-id'))
        // } else{
            id = Number(target.getAttribute('data-id'))
        // }

        if (categories.indexOf(id) != -1){
            categories.splice(categories.indexOf(id));
            target.classList.remove('is-active');
            $('[data-map="subCategory"]').removeClass('is-active');
        } else{
            // categories.push(id);
            categories = [id];
            target.classList.add('is-active')
        }

        this.categories = categories;

        this.toggleMarkers(categories);
    }

    toggleSubCategory(e) {
        let target = e.currentTarget;
        let categories = this.subcategories;
        let id = '';

        if (e.type === 'change'){
            id = Number(target[target.options.selectedIndex].getAttribute('data-id'))
        } else{
            id = Number(target.getAttribute('data-id'))
        }

        if (categories.indexOf(id) != -1){
            categories.splice(categories.indexOf(id));
            target.classList.remove('is-active');
        } else{
            // categories.push(id);
            $('[data-map="subCategory"]').removeClass('is-active');
            categories = [id];
            target.classList.add('is-active')
        }

        this.subcategories = categories;

        if(categories.length == 0) {
            categories = this.categories;
        }

        this.toggleMarkers(categories);
    }

    toggleMarkers(categories) {
        let markers = this.map.markers;

        console.log(categories);

        markers.forEach(marker => {
            if(categories.length) {
                if(categories.indexOf(marker.category) != -1 || categories.indexOf(marker.parent) != -1) {
                    marker.setVisible(true);
                }
                else {          
                    marker.setVisible(false);
                }
            }else{
                marker.setVisible(true);
            }
        })

        centerMap(this.map);
    }

    // toggleCrossInfowindow(e){
    //     e.currentTarget.parentNode.parentNode.classList.add('c-map_infoWindows_close');
    // }

    closeInfowindow(e) {
        $('.c-map_container').removeClass('is-open');
    }
}

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap( $el ) {
    // Find marker elements within map.
    var $markers = $el.find('.marker');

    var styles = [
        {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text",
            "stylers": [
                {
                    "weight": "4.44",                    
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#2D8056"
                },
                {
                    "weight": "5.66"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#FFFFFF"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#F2F2F2"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#FFDC9D"
                },
                {
                    "lightness": "76"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#FCC11F"
                },
                {
                    "lightness": "1"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#2D8056"
                },
                {
                    "visibility": "on"
                },
                {
                    "lightness": "62"
                },
                {
                    "saturation": "3"
                },
                {
                    "gamma": "1.00"
                }
            ]
        }
    ];

    // Create gerenic map.
    var mapArgs = {
      zoom: $el.data('zoom') || 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: styles,
      gestureHandling: 'cooperative',
    };
    var map = new google.maps.Map( $el[0], mapArgs );

    // Add markers.
    map.markers = [];
    $markers.each(function(){
        initMarker( $(this), map );
    });

    map.data.loadGeoJson(
      '/app/uploads/2022/04/CCPM.geojson'
    );

    map.data.setStyle(function (feature) {
      return {
        title: feature.getProperty('nom'),
        fillColor: '#b9d934',
        strokeColor: '#195f34',
        strokeWeight: 1,
        fillOpacity: 0.3,
        // clickable: false,
        zIndex: 1,
        weight: 1,
        cursor: "default"
      };
    });

    map.data.addListener('mouseover', function (event) {
      map.data.revertStyle();
      map.data.overrideStyle(event.feature, {
        strokeWeight: 3,
        fillOpacity: 0.5,
      });
      $('#info-box').show();
      document.getElementById('info-box').textContent = event.feature.getProperty('nom');
    });

    map.data.addListener('mouseout', function (event) {
      map.data.revertStyle();
      $('#info-box').hide();
    });

    // Center map based on markers.
    centerMap( map );

    // Return map instance.
    return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker( $marker, map ) {
    // Get position from marker.
    var lat = $marker.data('lat');
    var lng = $marker.data('lng');
    var latLng = {
        lat: parseFloat( lat ),
        lng: parseFloat( lng )
    };

    var icon = {
        url : '/app/themes/trinity2/public/images/localisation.svg',
        scaledSize: new google.maps.Size(48, 37)
    };

    // Create marker instance.
    var marker = new google.maps.Marker({
        position : latLng,
        map: map,
        category : $marker.data('category'),
        parent : $marker.data('parent'),
        data : {id : $marker.data('id')},
        icon: icon
    });

    // Append to reference for later use.
    map.markers.push( marker );

    // Create info window.
    var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
    });

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, 'click', function(e) {
        map.panTo(marker.getPosition());
        openPopup(marker.data.id);
    });
}

function openPopup(id){
    
    let categories = $('.c-map_filtres .is-active');
    let ids = [];
    categories.each(function() {
        ids.push($(this).attr('data-id'));
    });

    $.ajax({
        url: '/wp/wp-admin/admin-ajax.php',
        type: 'POST',
        data: {action: 'map_infowindow', id: id, cat_ids: ids},
        success: function( response ){
            $('.c-map_infowindow').html(response.content);
            $('.c-map_container').addClass('is-open');
        },
      });
}

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
function centerMap( map ) {

    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function( marker ){
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });

    // Case: Single marker.
    if( map.markers.length == 1 ){
        map.setCenter( bounds.getCenter() );

    // Case: Multiple markers.
    } else{
        map.fitBounds( bounds );
    }
}
