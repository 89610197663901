import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                accessibility: 'toggleAccessibility',
            }
        }
    }

    init() {
        $('body').click(function(e) {
            if($('#pojo-a11y-toolbar').hasClass('pojo-a11y-toolbar-open')) {
                var container = $('#pojo-a11y-toolbar');
                if (!container.is(e.target) && container.has(e.target).length === 0 && $('.accessibilite-btn').has(e.target).length === 0) {
                container.removeClass('pojo-a11y-toolbar-open');
                }
            }
        });
    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        const text = burger.querySelector('.-hover');
        const html = document.querySelector('html');
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            burger.classList.remove('is-active');
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
            if($('#pojo-a11y-toolbar').hasClass('pojo-a11y-toolbar-open')) {
                this.toggleAccessibility();
            }
        }
    }

    toggleAccessibility() {
        $('#pojo-a11y-toolbar').toggleClass('pojo-a11y-toolbar-open');
    }
}
